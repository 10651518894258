import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';

// Components
const DesktopDynamicZone = dynamic(() => import('./desktop'));
const MobileDynamicZone = dynamic(() => import('./mobile'));

export default function DynamicZone(props) {
	const isMobile = useSelector((state) => state.common.isMobile);

	const DynamicZoneComponent = isMobile
		? MobileDynamicZone
		: DesktopDynamicZone;

	return <DynamicZoneComponent {...props} />;
}
