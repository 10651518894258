import Head from 'next/head';
import PropTypes from 'prop-types';

// utils
import { ID } from '@/utils/uniqueID';

/**
 * params:
 * data: structured data to attach
 */

export default function StructuredData(props) {
	if (!props || !props.data) return null;

	return (
		<Head>
			{props.data.map((sd) => (
				<script
					key={`jobJSON-${ID()}`}
					type="application/ld+json"
					dangerouslySetInnerHTML={{ __html: JSON.stringify(sd) }}
				/>
			))}
		</Head>
	);
}

StructuredData.propTypes = {
	data: PropTypes.array,
};
