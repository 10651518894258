import thankYouPageCheck from '@/utils/page-check/thank-you-page-check';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { componentTypes } from '../constants/componentTypes';

export default function useDynamicZoneProps(pageProps) {
	const filters = useSelector((state) => state.filter);
	const cmsGlobalData = useSelector((state) => state.cmsGlobalData.data);
	const location = useSelector((state) => state.router.location);

	const searchQueryValues = Object.fromEntries(
		new URLSearchParams(location.search)
	);

	const mobileComponentsToLoadInPriority = React.useMemo(() => {
		const scrollSectionsExists =
			pageProps.scrollSectionsExists ||
			pageProps.DynamicZone.some(
				({ __component }, index) =>
					__component === componentTypes.NavigationSection ||
					(index > 1 &&
						(__component === componentTypes.EventDetailPageBannerSection ||
							__component === componentTypes.LandingPageBannerSection))
			);

		const data = new Set();
		const limit = scrollSectionsExists ? pageProps.DynamicZone.length : 2;
		for (let i = 0; i < limit && i < pageProps.DynamicZone.length; i++) {
			data.add(pageProps.DynamicZone[i].__component);
		}

		return data;
	}, []);

	return {
		filters,
		components: pageProps.DynamicZone,
		page: {
			id: pageProps.pageId,
			slug: pageProps.slug,
			showIFrameSection: thankYouPageCheck(location)
				? searchQueryValues.status === '1'
				: true,
		},
		form: {
			pageFormId: pageProps.pageFormId,
			pageForm: get(pageProps, 'form.data.attributes', null),
			authUserForm: get(pageProps, 'authUserForm.data.attributes', null),
			constants: pageProps.formPageConstants || {},
			config: pageProps.formPageConfig || {},
			useOtpFlowForForm: pageProps.useOtpFlowForForm,
			paymentDetail: pageProps.paymentDetail || {},
		},
		cmsGlobalData: cmsGlobalData || {},
		searchQueryValues,
		mobileComponentsToLoadInPriority,
	};
}
